import {
  ByErrorFilled,
  ByWarningFilled,
  BySuccessFilled,
} from '@/app/components/icons/CustomIconPack';
import { ReactNode } from 'react';
import { AlertTypeEnum } from '@/app/utils/constants';
import styles from './CustomAlert.module.scss';
import cn from 'classnames';

type Props = {
  type: AlertTypeEnum;
  title: string;
  children?: ReactNode;
};

export default function CustomAlert({ type, title, children }: Props) {
  const icons = {
    danger: ByErrorFilled,
    warning: ByWarningFilled,
    success: BySuccessFilled,
  };
  const color = type;
  const colorClassname = styles[color];
  const Icon = icons[type];

  return (
    <div className={cn(styles.customAlert, `${colorClassname} rounded d-flex p-1`)}>
      <div className={cn(styles.stripeWrapper)}>
        <div className={cn(styles.stripe, `rounded h-100`)}>&nbsp;</div>
      </div>
      <div className={cn(styles.iconWrapper, `px-3 pt-2`)}>
        <Icon size={24} />
      </div>
      <div className={`pt-2 pe-2 pb-${children ? '3' : '2'}`}>
        <h5 className="title pt-1">{title}</h5>
        <div>{children}</div>
      </div>
    </div>
  );
}
