'use client';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { FormFeedback, Input, Label } from 'reactstrap';
import { ChangeEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import styles from '@/app/components/shared/Forms/InputController.module.scss';

interface Props extends FieldValues {
  name: string;
  label?: string | ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

export default function InputController(props: Props) {
  const { control } = useFormContext();
  const { label, onChange } = props;
  const cssClasses = classNames(props.readOnly ? styles.readonlyField : '');

  return (
    <Controller
      {...props}
      control={control}
      render={({ field, fieldState }) => (
        <>
          {label && (
            <Label className={classNames({ 'text-danger': fieldState.error })}>{label}</Label>
          )}
          <Input
            {...props}
            {...field}
            className={cssClasses}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e);
            }}
            invalid={!!fieldState.error}
          />
          {fieldState.error && <FormFeedback>{fieldState.error.message}</FormFeedback>}
        </>
      )}
    />
  );
}
