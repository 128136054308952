'use client';
import LoginForm from '@/app/(routes)/[locale]/login/LoginForm';
import { useTranslations } from 'next-intl';

export default function LoginPage() {
  const t = useTranslations('pages.accountManagement.loginPage');

  return (
    <div>
      <h4>{t('heading')}</h4>
      <p>{t('instruction')}</p>
      <LoginForm />
    </div>
  );
}
