'use client';

import { FC, JSX, memo, MouseEventHandler } from 'react';
import { Button as ButtonRS, ButtonProps } from 'reactstrap';
import styles from '@/app/components/shared/Buttons/Button.module.scss';
import { ColorTypeEnum, SizeEnum } from '@/app/utils/constants';
import cn from 'classnames';
import Image, { StaticImageData } from 'next/image';
import { IconType } from 'react-icons';
import { BsGear } from 'react-icons/bs';
import Loader from '@/app/components/shared/Loader/Loader';

enum IconPosition {
  START = 'start',
  END = 'end',
}

export type Props = {
  /**
   * Button contents
   */
  children?: string;
  /**
   * How large should be the button
   */
  size?: SizeEnum;
  /**
   * What background color to use
   */
  color?: ColorTypeEnum;
  /**
   * Indicates whether the button is disabled.
   */
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: (e: MouseEventHandler<HTMLButtonElement>) => void;
  /**
   * Custom outline style for type of color primary|success|danger
   */
  outlineL2?: boolean;
  /**
   * Icon start for button, if you want to use icon from react-icons or custom icon pack, example usage:
   * ```iconStart={()=> BsGear({size:16})}```
   */
  iconStart?: () => JSX.Element;
  /**
   * Icon end for button, if you want to use icon from react-icons or custom icon pack, example usage:
   * ```iconEnd={()=> BsGear({size:16})}```
   */
  iconEnd?: () => JSX.Element;
  /**
   * Custom image for button
   */
  img?: StaticImageData;
  /**
   * Indicates whether the button is loading.
   */
  isLoading?: boolean;
} & ButtonProps;

/**
 * Button UI component for user interaction
 */
const Button: FC<Props> = ({
  children,
  size = SizeEnum.SM,
  color = ColorTypeEnum.PRIMARY,
  disabled = false,
  className = '',
  outlineL2 = false,
  onClick,
  iconStart,
  iconEnd,
  img,
  isLoading = false,
  ...rest
}) => {
  const colorClassName = size ? styles[color] : '';
  const isDisabled = disabled || isLoading;

  const getIcon = (Icon: IconType | undefined, position: IconPosition) => {
    if (!Icon) return;
    return (
      <span
        className={cn('d-inline-flex', {
          ['me-2']: position === IconPosition.START,
          ['ms-2']: position === IconPosition.END,
        })}
      >
        <Icon />
      </span>
    );
  };

  const calculateSize = (size: SizeEnum) => {
    switch (size) {
      case SizeEnum.SM:
        return 14;
      case SizeEnum.MD:
        return 16;
      case SizeEnum.LG:
        return 18;
    }
  };

  const renderLoader = (className?: string) => (
    <>
      {isLoading && (
        <Loader
          isLoading={isLoading}
          size={calculateSize(size)}
          className={`${styles.btnLoader} ${className}`}
        />
      )}
    </>
  );

  const renderImg = (img: StaticImageData, size: SizeEnum) => {
    const imgSizeClass = size ? styles[size] : '';
    return (
      <>
        {renderLoader()}
        <Image className={`${styles.img} ${imgSizeClass}`} src={img.src} alt={img.src} fill />
        {getIcon(() => BsGear({ size: calculateSize(size) }), IconPosition.START)}
        {<span className={'btn-content'}>Text</span>}
        {getIcon(() => BsGear({ size: calculateSize(size) }), IconPosition.END)}
      </>
    );
  };

  const getButtonContent = () => {
    if (img) return renderImg(img, size);
    return (
      <>
        {renderLoader()}
        {getIcon(iconStart, IconPosition.START)}
        <span className={'btn-content'}>{children}</span>
        {getIcon(iconEnd, IconPosition.END)}
      </>
    );
  };

  return (
    <ButtonRS
      className={cn(`${styles.btn} ${className} ${size} ${colorClassName}`, {
        [styles.disabled]: isDisabled,
        [styles.outlineL2]: outlineL2,
        [styles.isImg]: img,
      })}
      size={size}
      color={color}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      <div className={styles.btnContentWrapper}>{getButtonContent()}</div>
    </ButtonRS>
  );
};

export default memo(Button);
